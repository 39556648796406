.location .box {
  position: relative;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.location img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #e6e6e6;
}

.location .details {
  padding: 15px;
  background: #fff;
}

.location .details h5 {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold;
}

.location .details p {
  margin: 10px 0;
  font-size: 14px;
  color: #333;
}

.location .details a {
  display: inline-block;
  margin-top: 10px;
  color: #2d4e8e;
  text-decoration: none;
}

.location .details a:hover {
  text-decoration: underline;
}
