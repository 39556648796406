.hero {
  height: 90vh; /* Keep as is */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden; /* Ensure no overflow */
}

.carousel {
  width: 100vw; /* Ensure carousel takes full viewport width */
  position: relative; /* Position relative for proper layering */
  margin: 0; /* Remove margins */
  padding: 0; /* Remove any padding */
  overflow: hidden; /* Ensure no overflow */
}

.carousel .slide {
  width: 100vw; /* Full width for each slide */
  max-height: 90vh; /* Limit height */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  overflow: hidden; /* Ensure no overflow */
}

.carousel .slide img {
  height: 90vh; /* Set fixed height to fit */
  object-fit: cover; /* Cover the area */
}

.hero .container {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  position: relative; /* Keep relative for text over images */
  z-index: 2; /* Ensure content is above images */
}

@media screen and (max-width: 800px) {
  .hero .container {
    padding-right: 0;
  }
}
