.footerContact {
  background-color: #2766ae;
  padding: 40px 0;
  color: #fff;
}

.footerContact h1 {
  font-size: 40px;
  text-align: center;
  margin: 0;
}

footer {
  background-color: #1d2636;
  padding: 50px 0;
  color: #fff;
}

footer .container {
  display: grid;
  grid-template-columns: 5fr 2fr 2fr 2fr;
  grid-gap: 20px;
}

footer img {
  width: 259px;
  height: 100px;
}

footer h2 {
  font-weight: 500;
  color: #fff;
}

footer p {
  color: grey;
  margin: 20px 0;
}

footer input {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  border-radius: 5px;
  border: none;
}

footer h3 {
  font-weight: 500;
  margin-bottom: 30px;
  color: #fff;
}

footer ul {
  display: block;
  padding-left: 0;
}

footer ul li {
  display: block;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  list-style: none;
}

.legal {
  text-align: center;
  padding: 20px;
  background: #1d2636;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.contact-button {
  background-color: #2d4e8e;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 20px;
  display: inline-block;
  text-decoration: none;
}

.contact-button:hover {
  background-color: #223b6d;
}

/* Useful Links Styling */
footer .useful-links ul li a {
  color: #87CEFA; /* Light blue color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

footer .useful-links ul li a:hover {
  color: #4682B4; /* Darker blue on hover */
}

.bms-access {
  margin-top: 20px;
}

.bms-access a {
  color: #007bff; /* Example link color */
  text-decoration: underline;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px; /* Add padding to prevent overflow */
  }
}

@media screen and (max-width: 500px) {
  footer .container {
    grid-template-columns: 1fr; /* Stack columns on very small screens */
    padding: 0 5px; /* Add more padding for smaller devices */
  }
  
  footer h1 {
    font-size: 30px; /* Adjust heading size for smaller screens */
  }

  footer h2 {
    font-size: 18px; /* Adjust subheading size */
  }

  footer input {
    padding: 10px; /* Reduce padding */
  }

  .contact-button {
    padding: 10px 20px; /* Adjust button padding */
  }
}