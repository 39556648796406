.recent {
  max-width: 100%; /* Ensure it does not exceed the viewport */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.recent .text {
  padding: 20px; /* Adjust padding for smaller screens */
  margin: 0 auto; /* Center the content */
}

.recent .category span {
  padding: 4px 10px; /* Adjust padding for smaller screens */
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px; /* Adjust font size */
  display: inline-block;
}

.recent .category i {
  font-size: 18px; /* Adjust icon size */
  color: #bec7d8;
}

.recent p {
  color: #72809d;
}

.recent .button {
  border-top: 1px solid #e4e8f3;
  padding: 10px 20px; /* Adjust padding for buttons */
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 800px) {
  .recent .text {
    padding: 15px; /* Further reduce padding */
  }

  .recent .category span {
    padding: 4px 8px; /* Further reduce padding */
  }

  .recent .button {
    padding: 8px 15px; /* Adjust button padding */
  }
}

@media screen and (max-width: 500px) {
  .recent .text {
    padding: 10px; /* Adjust padding for very small screens */
  }

  .recent .category span {
    font-size: 11px; /* Further adjust font size */
  }

  .recent .category i {
    font-size: 16px; /* Adjust icon size for smaller screens */
  }
}