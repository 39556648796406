.about {
  margin-bottom: 80px;
  
}

.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  
}

.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
  
}

.about .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.left {
max-width: 500px;
  flex: 1;
  margin-left: 60px;
  margin-top: 30px;
  padding: 20px; /* Padding within the border */
  word-wrap: break-word;
}

.right {

  flex: 1;
  max-width: 550px; /* Limit the width of the image */
  margin-top: 60px;
  height: 400px;
  margin-right:50px ;
}



@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
    align-items: center;
  }

  .about p {
    padding: 0;
  }

  .about .container img {
    margin-top: 50px;
    max-width: 100%;
  }
}
